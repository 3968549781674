import React from 'react';
import { Helmet } from 'react-helmet';
import PublicHeader from '../../components/layout/Header/PublicHeader';
import PublicFooter from '../../components/layout/Footer/PublicFooter';
import classes from './About.module.css';

const About = () => {
  return (
    <>
      <Helmet>
        <title>About Us - JL Travel Agency</title>
        <meta name="description" content="Learn more about JL Travel Agency, our journey, and our commitment to providing exceptional travel services. Explore the history of our company and how we have grown to offer excellent tours and booking services." />
        <meta name="keywords" content="JL Travel Agency, about us, travel agency history, Philippine travel agency, travel services" />
        <meta property="og:title" content="About Us - JL Travel Agency" />
        <meta property="og:description" content="Learn more about JL Travel Agency, our history, and our commitment to providing the best travel services." />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="About Us - JL Travel Agency" />
        <meta name="twitter:description" content="Discover JL Travel Agency's history and mission to provide the best trip possible." />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={`${window.location.origin}/about-us`} />
      </Helmet>
      <PublicHeader />
      <div className={classes.aboutContainer} id="about-section">
        <section className={classes.heroSection}>
          <div className={classes.heroText}>
            <h1>About Us</h1>
            <p>We strive to ensure your experience is the best trip possible.</p>
          </div>
        </section>
        
        <section className={classes.sectionHolder}>
          <div className={classes.sectionContent}>
            <h2>Company Background</h2>
            <p>
              Our company, JL Travel Agency (JLTA), was founded to offer great service and ensure your experience is the best trip possible.
            </p>
            <p>
              It all started in September 2017 when our founder, <b>Jessica G. Urbano</b>, an OFW in Dubai, was looking for an extra source of income. She bought an online portal and became a part-time online ticketing agent. She encountered a lot of problems in flight bookings, cancellations, delays, and was even doubted as a scammer.
            </p>
            <p>
              In 2020, when the pandemic hit, over 200 flights were canceled. <b>Jessica</b> rose to the occasion and reassured customers that all their queries would be addressed. She overcame all these challenges and continued to grow stronger.
            </p>
          </div>
        </section>

        <section className={classes.sectionHolder}>
          <div className={classes.sectionContent}>
            <h2>Our Mission and Vision</h2>
            <p>
              <b>Mission:</b> Empower travelers by simplifying the journey from inspiration to destination, ensuring that every step of the booking process is efficient, intuitive, and enjoyable.
            </p>
            <p>
              <b>Vision:</b> Grow into a dynamic group of companies that transforms the way people explore the world, fostering a community of travelers who seek unforgettable journeys.
            </p>
          </div>
        </section>

        <section className={classes.sectionHolder}>
          <div className={classes.sectionContent}>
            <h2>Our Services</h2>
            <p>
              On January 2022, JL Travel Agency had its first home-based office with a Mayor’s permit to operate (with fire and health permits) and is BIR Registered. With over 200 bookings within a month, it is proven and tested that you can trust JLTA.
            </p>
            <p>
              JLTA offers excellent service on Airline ticketing, Tour packages, and Hotel booking for International and Domestic destinations. We specialize in Tour packages for Bohol and Boracay, 2GO Ferry booking, Passport, and Visa assistance.
            </p>
          </div>
        </section>

        <section className={classes.sectionHolder}>
          <div className={classes.sectionContent}>
            <h2>Our Commitment</h2>
            <p>
              JL Travel Agency was formerly known as JL Travel Adventure, which stands for "Just Love Travel Adventure." Our founder loves to travel and understands how it feels, and this has become our commitment to “Ensure your experience is the best trip possible.”
            </p>
          </div>
        </section>

      </div>
      <PublicFooter />
    </>
  );
};

export default About;
