import React from 'react';
import classes from './PrivacyPolicy.module.css';
import PublicHeader from '../../components/layout/Header/PublicHeader';
import PublicFooter from '../../components/layout/Footer/PublicFooter';


const PrivacyPolicy = () => {
  return (
    <>
        <PublicHeader />
        <section className={classes.privacy}>
        <h2>Our Privacy Policy</h2>
        
        <p>
            At JL Travel Agency, we deeply value your trust and are committed to protecting your privacy. We take the responsibility of handling your personal information with great care and diligence.
        </p>
        
        <p>
            When you book with us, we may need to collect some personal details, like your name, email address, passport number, and payment information. This is essential for processing your bookings and ensuring your travel arrangements go smoothly. We may also share some of this information with trusted partners, such as hotels and transportation services, to provide you with the best possible experience.
        </p>
        
        <p>
            This privacy policy (referred to as the "Policy") explains how we collect, use, and protect your personal information. The Policy applies to all personal information we process when you do business with us, use our services, visit our website, or interact with us in any way.
        </p>
        
        <h3>Our Commitment</h3>
        <p>
            We have implemented internal policies and procedures to ensure your personal information is adequately protected. Our team is trained on the importance of privacy and the measures we take to secure your data.
        </p>
        
        <h3>What Information Do We Collect?</h3>
        <p>
            We may collect various types of personal information, including your identification details, contact information, booking details, and travel preferences. This information helps us provide you with a personalized and seamless experience.
        </p>
        
        <h3>How Do We Use Your Information?</h3>
        <p>
            Your information is used to manage your bookings, communicate with you, and enhance your customer experience. We may also use your data for marketing purposes, if you have consented to receive such communications.
        </p>
        
        <h3>How Do We Protect Your Information?</h3>
        <p>
            We use a combination of physical, organizational, and technological measures to safeguard your personal information. For example, we employ industry-standard encryption to protect your data during online transactions.
        </p>
        
        <h3>How Long Do We Keep Your Information?</h3>
        <p>
            We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Policy or to comply with legal requirements. We regularly review and securely delete or anonymize data that is no longer needed.
        </p>
        
        <h3>How to Contact Us</h3>
        <p>
            If you have any questions, comments, or requests regarding this Policy or your personal information, please feel free to contact us:
        </p>
        <p>
            <strong>Address:</strong> Bool, Tagbilaran City, Philippines<br />
            <strong>Phone:</strong> +63 938 609 1908<br />
            <strong>Email:</strong> travel.jl@yahoo.com
        </p>
        
        <h3>The person responsible for the processing of personal data is:</h3>
        <p>
            <strong>Name:</strong> Jessica G. Urbano, Owner<br />
            <strong>Company:</strong> JL Travel Agency<br />
            <strong>Address:</strong> Bool, Tagbilaran City, Philippines
        </p>
        </section>
        <PublicFooter />
    </>
  );
};

export default PrivacyPolicy;
