import React from 'react';
import classes from './Introduction.module.css';

const Introduction = () => {
  return (
      <section className={classes.introduction} id="about-section">
        <div className={classes.introductionContent}>
          <h2>Your Journey, Our Passion</h2>
          <p>
            At JL Travel Agency, we don't just book trips—we craft personalized adventures tailored to your dreams. Whether you’re chasing sunsets on a tropical beach, exploring ancient wonders, or seeking the thrill of the unknown, we turn your travel fantasies into reality. With us, every journey is a story waiting to be told.
          </p>
        </div>
      </section>
  );
};

export default Introduction;
