import React from 'react';
import { Helmet } from 'react-helmet';
import { BASE_URL } from '../../utils/constants';
import PublicHeader from '../../components/layout/Header/PublicHeader';
import PublicFooter from '../../components/layout/Footer/PublicFooter';
import AirlineIcon from '../../assets/icons/take-off.png';
import FerryIcon from '../../assets/icons/ship.png';
import InsuranceIcon from '../../assets/icons/healthcare.png';
import HotelIcon from '../../assets/icons/hotel.png';
import LocalTourIcon from '../../assets/icons/tour-guide.png';
import InternationalTourIcon from '../../assets/icons/international.png';
import TransferIcon from '../../assets/icons/transfer.png';
import PSAPassportIcon from '../../assets/icons/passport.png';
import classes from './Services.module.css';

const Services = () => {
  const facebookPageUrl = "https://www.facebook.com/jl.travel.agency17";

  return (
    <>
      <Helmet>
        <title>JL Travel Agency - Our Services</title>
        <meta name="description" content="Discover a wide range of travel services offered by JL Travel Agency, including airline and ferry bookings, travel insurance, hotel accommodations, local and international tours, and more. Book now!" />
        <meta name="keywords" content="travel services, airline booking, ferry booking, travel insurance, hotel booking, local tours, international tours, airport transfer, passport services, JL Travel Agency" />
        <meta property="og:title" content="JL Travel Agency - Our Services" />
        <meta property="og:description" content="Explore the various services offered by JL Travel Agency, from domestic and international airline bookings to comprehensive travel insurance and hotel accommodations." />
        <meta property="og:image" content={AirlineIcon} />
        <meta property="og:url" content={`${BASE_URL}/services`} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="JL Travel Agency - Our Services" />
        <meta name="twitter:description" content="Discover our range of travel services, including local and international tours, airport transfers, and more. Book with JL Travel Agency today." />
        <meta name="twitter:image" content={AirlineIcon} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={`${BASE_URL}/services`} />
      </Helmet>    
      <PublicHeader />
      <section className={classes.services}>
        <h2 className={classes.title}>SERVICES</h2>
        <div className={classes.serviceGrid}>
          <a href={facebookPageUrl} target="_blank" rel="noopener noreferrer" className={classes.serviceBox}>
            <img src={AirlineIcon} alt="Airline Booking" className={classes.icon} />
            <p>Airline Booking (Domestic &amp; International)</p>
          </a>
          <a href={facebookPageUrl} target="_blank" rel="noopener noreferrer" className={classes.serviceBox}>
            <img src={FerryIcon} alt="Ferry Booking" className={classes.icon} />
            <p>Ferry Booking (2GO, Oceanjet, Lite Shipping, Transasia, and many more)</p>
          </a>
          <a href={facebookPageUrl} target="_blank" rel="noopener noreferrer" className={classes.serviceBox}>
            <img src={InsuranceIcon} alt="Travel Insurance" className={classes.icon} />
            <p>Travel Insurance (Chubb Travel Insurance)</p>
          </a>
          <a href={facebookPageUrl} target="_blank" rel="noopener noreferrer" className={classes.serviceBox}>
            <img src={HotelIcon} alt="Hotel Booking" className={classes.icon} />
            <p>Hotel Booking</p>
          </a>
          <a href={facebookPageUrl} target="_blank" rel="noopener noreferrer" className={classes.serviceBox}>
            <img src={LocalTourIcon} alt="Local Tour" className={classes.icon} />
            <p>Local Tour (Bohol, Cebu, Boracay, Siquijor, Palawan, and more)</p>
          </a>
          <a href={facebookPageUrl} target="_blank" rel="noopener noreferrer" className={classes.serviceBox}>
            <img src={InternationalTourIcon} alt="International Tour" className={classes.icon} />
            <p>International Tour</p>
          </a>
          <a href={facebookPageUrl} target="_blank" rel="noopener noreferrer" className={classes.serviceBox}>
            <img src={TransferIcon} alt="Land Transfer" className={classes.icon} />
            <p>Land Transfer (Airport/seaport transfer)</p>
          </a>
          <a href={facebookPageUrl} target="_blank" rel="noopener noreferrer" className={classes.serviceBox}>
            <img src={PSAPassportIcon} alt="PSA & Passport" className={classes.icon} />
            <p>PSA &amp; Passport</p>
          </a>
        </div>
      </section>
      <PublicFooter />
    </>
  );
};

export default Services;


// import React from 'react';
// import { Helmet } from 'react-helmet';
// import { BASE_URL } from '../../utils/constants';
// import PublicHeader from '../../components/layout/Header/PublicHeader';
// import PublicFooter from '../../components/layout/Footer/PublicFooter';
// import AirlineIcon from '../../assets/icons/take-off.png';
// import FerryIcon from '../../assets/icons/ship.png';
// import InsuranceIcon from '../../assets/icons/healthcare.png';
// import HotelIcon from '../../assets/icons/hotel.png';
// import LocalTourIcon from '../../assets/icons/tour-guide.png';
// import InternationalTourIcon from '../../assets/icons/international.png';
// import TransferIcon from '../../assets/icons/transfer.png';
// import PSAPassportIcon from '../../assets/icons/passport.png';
// import classes from './Services.module.css';

// const Services = () => {
//   return (
//     <>
//       <Helmet>
//         <title>JL Travel Agency - Our Services</title>
//         <meta name="description" content="Discover a wide range of travel services offered by JL Travel Agency, including airline and ferry bookings, travel insurance, hotel accommodations, local and international tours, and more. Book now!" />
//         <meta name="keywords" content="travel services, airline booking, ferry booking, travel insurance, hotel booking, local tours, international tours, airport transfer, passport services, JL Travel Agency" />
//         <meta property="og:title" content="JL Travel Agency - Our Services" />
//         <meta property="og:description" content="Explore the various services offered by JL Travel Agency, from domestic and international airline bookings to comprehensive travel insurance and hotel accommodations." />
//         <meta property="og:image" content={AirlineIcon} />
//         <meta property="og:url" content={`${BASE_URL}/services`} />
//         <meta property="og:type" content="website" />
//         <meta name="twitter:card" content="summary_large_image" />
//         <meta name="twitter:title" content="JL Travel Agency - Our Services" />
//         <meta name="twitter:description" content="Discover our range of travel services, including local and international tours, airport transfers, and more. Book with JL Travel Agency today." />
//         <meta name="twitter:image" content={AirlineIcon} />
//         <meta name="robots" content="index, follow" />
//         <link rel="canonical" href={`${BASE_URL}/services`} />
//       </Helmet>    
//       <PublicHeader />
//       <section className={classes.services}>
//         <h2 className={classes.title}>SERVICES</h2>
//         <div className={classes.serviceGrid}>
//           <div className={classes.serviceBox}>
//             <img src={AirlineIcon} alt="Airline Booking" className={classes.icon} />
//             <p>Airline Booking (Domestic &amp; International)</p>
//           </div>
//           <div className={classes.serviceBox}>
//             <img src={FerryIcon} alt="Ferry Booking" className={classes.icon} />
//             <p>Ferry Booking (2GO, Oceanjet, Lite Shipping, Transasia, and many more)</p>
//           </div>
//           <div className={classes.serviceBox}>
//             <img src={InsuranceIcon} alt="Travel Insurance" className={classes.icon} />
//             <p>Travel Insurance (Chubb Travel Insurance)</p>
//           </div>
//           <div className={classes.serviceBox}>
//             <img src={HotelIcon} alt="Hotel Booking" className={classes.icon} />
//             <p>Hotel Booking</p>
//           </div>
//           <div className={classes.serviceBox}>
//             <img src={LocalTourIcon} alt="Local Tour" className={classes.icon} />
//             <p>Local Tour (Bohol, Cebu, Boracay, Siquijor, Palawan, and more)</p>
//           </div>
//           <div className={classes.serviceBox}>
//             <img src={InternationalTourIcon} alt="International Tour" className={classes.icon} />
//             <p>International Tour</p>
//           </div>
//           <div className={classes.serviceBox}>
//             <img src={TransferIcon} alt="Land Transfer" className={classes.icon} />
//             <p>Land Transfer (Airport/seaport transfer)</p>
//           </div>
//           <div className={classes.serviceBox}>
//             <img src={PSAPassportIcon} alt="PSA & Passport" className={classes.icon} />
//             <p>PSA &amp; Passport</p>
//           </div>
//         </div>
//       </section>
//       <PublicFooter />
//     </>
//   );
// };

// export default Services;
