import React from 'react';
import classes from './GeneralTermsConditions.module.css';
import PublicHeader from '../../components/layout/Header/PublicHeader';
import PublicFooter from '../../components/layout/Footer/PublicFooter';

const GeneralTermsConditions = () => {
  return (
    <>
        <PublicHeader />
        <section className={classes.terms}>
        <h2>General Terms & Conditions</h2>
        
        <p>
            Welcome to JL Travel Agency! We're pleased to offer you our travel services, and we want to ensure you have a smooth experience. By booking with us through this website, you agree to the following terms and conditions.
        </p>
        
        <h3>1. Our Responsibility</h3>
        <p>
            At JL Travel Agency, we strive to provide accurate information and help you find the best travel options. After completing your booking, you'll receive an invoice and travel documents as per our policies.
        </p>
        
        <h3>2. Your Responsibility</h3>
        <p>
            We kindly ask that you confirm all the information you provide is accurate. Please make sure you have the necessary travel documents, such as passports and visas, before your trip. Unfortunately, we cannot be held responsible for any issues arising from incorrect or missing documents.
        </p>
        
        <h3>3. Privacy & Confidentiality</h3>
        <p>
            Your privacy is important to us. We won’t share your personal information without your permission, except where necessary to process your bookings. Rest assured, we handle your data with care and respect.
        </p>
        
        <h3>4. Prices & Payment Methods</h3>
        <p>
            All prices are listed in Philippine Pesos and are subject to change. Payment is required at the time of booking, and all transactions are final. We accept the following payment methods for your convenience: GCASH, MAYA, BANK TRANSFER (BDO), PALAWAN PAY, and CEBUANA.
        </p>
        
        <h3>5. Delivery of Documents</h3>
        <p>
            After booking, we’ll send your travel documents via email. Please review them carefully and let us know immediately if there are any issues.
        </p>
        
        <h3>6. Travel Insurance</h3>
        <p>
            We highly recommend purchasing travel insurance to protect your trip. You can choose from a range of plans that suit your needs.
        </p>
        
        <h3>7. Liability</h3>
        <p>
            JL Travel Agency acts as an intermediary between you and the service providers (e.g., airlines, hotels). While we ensure the quality of our partners, we are not responsible for their actions or any issues that may arise during your travels.
        </p>
        
        <h3>8. Images</h3>
        <p>
            The images on our website are for illustrative purposes only. Please verify all details before making a booking.
        </p>
        
        <h3>9. Governing Law</h3>
        <p>
            These terms are governed by the laws of the Philippines. Any disputes will be handled in the courts of the Philippines.
        </p>
        
        <h3>10. Contact Information</h3>
        <p>
            If you have any questions or need assistance with your booking, please don’t hesitate to contact us. We’re here to help!
        </p>
        </section>
        <PublicFooter />
    </>
  );
};

export default GeneralTermsConditions;
