import React from 'react';
import { useParams } from 'react-router-dom';
import PublicHeader from '../../components/layout/Header/PublicHeader';
import PublicFooter from '../../components/layout/Footer/PublicFooter';
import classes from './TourDetails.module.css';
import toursData from '../../assets/js/toursData'; 
import { formatDescription } from '../../utils/shared';

const TourDetails = () => {
  const { tourType } = useParams(); // This will get the tour_type from the URL

  // Find the tour data based on the tour_type
  const tour = toursData.find((t) => t.tour_type === tourType);

  const handleBookNow = () => {
    window.open('https://docs.google.com/forms/d/1Jj2hAn4kOE5qsM4QqRAsyZ-BiCJclTfTc-ncrfAF60A/viewform?pli=1&edit_requested=true', '_blank');
  };

  if (!tour) {
    return (
      <>
        <PublicHeader />
        <section className={classes.error}>
          <h2>Tour Not Found</h2>
          <p>Sorry, we couldn't find the tour you are looking for.</p>
        </section>
        <PublicFooter />
      </>
    );
  }

  const phrasesToBold = ['Hinagdanan Cave','Whale Shark Snorkeling','(BALICASAG & VIRGIN ISLAND)', 'Dauis Church', 'Chocolate Hills', 'Panglao Island','Danao Extreme Adventure','Abatan Firefly Watching Tour', 'Anda and Candijay'];

  return (
    <>
      <PublicHeader />
      <section className={classes.tourDetails}>
        <div className={classes.detailsContent}>
          <div className={classes.imageSection}>
            <img src={tour.image} alt={tour.title} className={classes.tourImage} />
          </div>
          <div className={classes.descriptionSection}>
            <h2>{tour.title}</h2>
            <p>{formatDescription(tour.description, phrasesToBold)}</p>
            <h4>Starting From: {tour.price}</h4>
            {tour.details.length > 0 && (
              <>
                <h5>Details</h5>
                <ul>
                  {tour.details.map((detail, index) => (
                    <li key={index}>{detail}</li>
                  ))}
                </ul>
              </>
            )}

            {tour.pricingPerPax && tour.pricingPerPax.length > 0 && (
              <>
                <h5>Price starts as follows:</h5>
                <div className={classes.pricingTableContainer}>
                  <table className={classes.pricingTable}>
                    <thead>
                      <tr>
                        <th>PAX</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tour.pricingPerPax.map((item, index) => (
                        <tr key={index}>
                          <td>{item.pax}</td>
                          <td>{item.price}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}

            {tour.inclusions.length > 0 && (
              <>
                <h5>Inclusions</h5>
                <ul>
                  {tour.inclusions.map((inclusion, index) => (
                    <li key={index}>{inclusion}</li>
                  ))}
                </ul>
              </>
            )}   

            {tour.itineraries.length > 0 && (
              <>
                <h5>Itineraries</h5>
                <ul>
                  {tour.itineraries.map((itinerary, index) => (
                    <li key={index}>{itinerary}</li>
                  ))}
                </ul>
              </>
            )} 

            {tour.exclusions.length > 0 && (
              <>
                <h5>Exclusions</h5>
                <ul>
                  {tour.exclusions.map((exclusion, index) => (
                    <li key={index}>{exclusion}</li>
                  ))}
                </ul>
              </>
            )}

            {tour.notes.length > 0 && (
              <>
                <h5>Notes</h5>
                <ul>
                  {tour.notes.map((note, index) => (
                    <li key={index}>{note}</li>
                  ))}
                </ul>
              </>
            )} 

            {tour.optional_activities.length > 0 && (
              <>
                <h5>Optional Activities</h5>
                <ul>
                  {tour.optional_activities.map((optional_activity, index) => (
                    <li key={index}>{optional_activity}</li>
                  ))}
                </ul>
              </>
            )}                        
            <button className={classes.bookButton} onClick={handleBookNow}>Reserve Now</button>
          </div>
        </div>
      </section>
      <PublicFooter />
    </>
  );
};

export default TourDetails;
