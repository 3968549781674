import React from 'react';
import PublicHeader from '../../components/layout/Header/PublicHeader';
import PublicFooter from '../../components/layout/Footer/PublicFooter';
import classes from './Bohol.module.css';

const Bohol = () => {
  return (
    <>
        <PublicHeader />
        <div className={classes.container}>
        <h1 className={classes.heading}>Discovering Bohol: The Heart of the Philippines</h1>
        
        <p className={classes.paragraph}>
            Bohol, located in the Central Visayas region of the Philippines, is a stunning island destination known for its unique natural landscapes and diverse wildlife. 
            It has become increasingly popular among tourists due to its charming scenery and exciting attractions. Bohol combines both natural beauty and rich cultural history, 
            making it a must-visit spot for anyone traveling through the Philippines. From its unusual Chocolate Hills to its fascinating tarsiers, Bohol captivates travelers 
            with a unique mix of wonder and tradition.
        </p>

        <h2 className={classes.subheading}>The Chocolate Hills</h2>
        <p className={classes.paragraph}>
            One of the island's most iconic attractions is the Chocolate Hills, a geological marvel that consists of over 1,200 cone-shaped hills that turn a chocolate-brown 
            color in the dry season. This natural phenomenon has become one of the most recognized symbols of Bohol and is one of the Philippines' most famous sights. While 
            scientific explanations point to the hills being a result of ancient coral deposits shaped by erosion, local legends tell stories of mythical giants and supernatural 
            battles that shaped these hills. Visitors can admire the Chocolate Hills from designated viewing decks or explore the area on an ATV, allowing for an up-close 
            encounter with this unique terrain.
        </p>

        <h2 className={classes.subheading}>The Philippine Tarsier</h2>
        <p className={classes.paragraph}>
            Another key attraction in Bohol is the Philippine tarsier, one of the smallest primates in the world, known for its large, expressive eyes and tiny body. The tarsier 
            is an endangered species, and Bohol is one of the few places where these shy creatures can be seen in their natural habitat. The Tarsier Sanctuary in Corella offers 
            visitors a chance to see tarsiers up close in a protected environment while learning about conservation efforts to protect this rare species. Observing these tiny, 
            delicate primates is a unique experience, as they cling to trees and leap silently through the forest, adding to Bohol's allure.
        </p>

        <h2 className={classes.subheading}>Panglao Island and Beaches</h2>
        <p className={classes.paragraph}>
            In addition to its famous Chocolate Hills and tarsiers, Bohol is also known for its pristine beaches, especially on Panglao Island. Panglao, just off the coast of 
            Bohol’s main island, boasts soft white sands and crystal-clear waters, making it a prime location for swimming, snorkeling, and diving. Alona Beach, the most popular 
            beach on Panglao, offers vibrant marine life and coral reefs, drawing divers from around the world. With its beachside resorts, restaurants, and relaxed nightlife, 
            Panglao Island provides the perfect getaway for those looking to unwind and enjoy tropical paradise.
        </p>

        <h2 className={classes.subheading}>History and Culture</h2>
        <p className={classes.paragraph}>
            Beyond its natural wonders, Bohol is steeped in history and culture. The Baclayon Church, built in the 16th century, is one of the oldest stone churches in the 
            Philippines and a UNESCO World Heritage site, reflecting Spanish colonial influence on the island. Boholanos also celebrate their heritage through the Sandugo Festival, 
            which commemorates the historic blood compact between Spanish explorer Miguel López de Legazpi and Bohol chieftain Datu Sikatuna. This festival is marked by colorful 
            parades, street dances, and local performances that showcase the island's traditions and warm community spirit.
        </p>

        <h2 className={classes.subheading}>Conclusion</h2>
        <p className={classes.paragraph}>
            Bohol’s blend of natural beauty, cultural richness, and friendly locals make it an unforgettable destination for any traveler. Whether you’re standing atop the Chocolate 
            Hills, observing a tarsier in its natural habitat, or relaxing on Panglao’s sandy shores, Bohol offers experiences that stay with visitors long after they’ve left the 
            island. For those looking to explore the true beauty of the Philippines, Bohol is a destination that promises adventure, relaxation, and a glimpse into the country’s 
            remarkable biodiversity and history.
        </p>
        
        <p className={classes.paragraph}><strong>To conclude, BOHOL is a captivating blend of natural wonders, rich history, and warm hospitality, offering an unforgettable experience for every traveler.</strong></p>
        
        <div className={classes.ctaContainer}>
            <button 
            onClick={() => window.location.href = '/tour-packages'} 
            className={classes.ctaButton}
            >
            Check Our Bohol Tour Offers
            </button>
        </div>
        </div>
        <PublicFooter />
    </>
  );
};

export default Bohol;
