import React from 'react';
import { useNavigate } from 'react-router-dom';
import toursData from './CebuToursData';
import classes from './CebuTours.module.css';

const CebuTours = () => {
  const navigate = useNavigate();

  const handleSeeMore = (tourType) => {
    navigate(`/tour-details/${tourType}`);
  };

  const handleBookNow = () => {
    window.open('https://docs.google.com/forms/d/1Jj2hAn4kOE5qsM4QqRAsyZ-BiCJclTfTc-ncrfAF60A/viewform?pli=1&edit_requested=true', '_blank');
  };

  return (
    <div className={classes.container}>
          <div className={classes.tourGrid}>
            {toursData.tours.map((tour) => (
              <div key={tour.id} className={classes.tourCard}>
                <img src={tour.image} alt={tour.title} className={classes.tourImage} />
                <div className={classes.tourContent}>
                  <p className={classes.tourLocation}>{tour.location}</p>
                  <h4 className={classes.tourTitle}>{tour.title}</h4>
                  
                  {/* Badge for Popularity */}
                  <div className={classes.badge}>
                    <span>{tour.badge}</span>
                  </div>

                  <span className={classes.tourPrice}>Starting From: {tour.price}</span>
                  <div className={classes.tourButtons}>
                    <button className={classes.bookButton} onClick={handleBookNow}>Reserve Now</button>
                    {/* <button
                      className={classes.seeMoreButton}
                      onClick={() => handleSeeMore(tour.tour_type)}
                    >
                      See More
                    </button> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
    </div>
  );
};

export default CebuTours;
