import { createContext } from 'react';

const UserContext = createContext({
  sharedState: null,
  setSharedState: () => {},
  isModalOpen: false,
  toggleModal: () => {}
});

export default UserContext;

// import { createContext } from 'react';

// const UserContext = createContext({ sharedState: null, setSharedState: () => {} }); // Updated default value

// export default UserContext;
