import React from 'react';
import classes from './PublicFooter.module.css';
import FacebookIcon from '../../../assets/icons/facebook.png';
import InstagramIcon from '../../../assets/icons/instagram.png';
import YouTubeIcon from '../../../assets/icons/youtube.png';
import TikTokIcon from '../../../assets/icons/tiktok.png';

const PublicFooter = () => {
  return (
    <footer className={classes.footer}>
      <div className={classes.footerContainer}>
        <div className={classes.topSection}>
          <div className={classes.contact}>
            <h3>Get In Touch</h3>
            <div className={classes.socialMediaIcons}>
              <a href="https://www.facebook.com/jl.travel.agency17/" target="_blank" rel="noopener noreferrer">
                <img src={FacebookIcon} alt="Facebook" className={classes.icon} />
              </a>
              <a href="https://www.instagram.com/jlta_travelandtours" target="_blank" rel="noopener noreferrer">
                <img src={InstagramIcon} alt="Instagram" className={classes.icon} />
              </a>
              <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
                <img src={YouTubeIcon} alt="YouTube" className={classes.icon} />
              </a>
              <a href="https://www.tiktok.com/@jlta.travelandtours" target="_blank" rel="noopener noreferrer">
                <img src={TikTokIcon} alt="TikTok" className={classes.icon} />
              </a>
            </div>
          </div>
          <div className={classes.links}>
            <a href="https://www.facebook.com/jl.travel.agency17" target="_blank" className={classes.linkItem}>
              <i className={`fas fa-sign-in-alt ${classes.icon}`}></i> Let's Talk – Find Your Perfect Deal Today!
            </a>
          </div>
        </div>
        <div className={classes.middleSection}>
          <div className={classes.column}>
            <h4>Destinations</h4>
            <ul>
              <li><a href="/tour-packages">All destinations</a></li>
              <li><a href="#">Boracay</a></li>
              <li><a href="#">Cebu</a></li>
              <li><a href="#">Cagayan</a></li>
            </ul>
          </div>
          <div className={classes.column}>
            <h4>Useful Links</h4>
            <ul>
              <li><a href="https://www.facebook.com/jl.travel.agency17" target="_blank">Travel Advisories</a></li>
              <li><a href="https://www.facebook.com/jl.travel.agency17" target="_blank">Visa Information</a></li>
              <li><a href="https://www.facebook.com/jl.travel.agency17" target="_blank">Car Rental</a></li>
              <li><a href="https://www.facebook.com/jl.travel.agency17" target="_blank">Accommodation Booking</a></li>
              <li><a href="https://www.facebook.com/jl.travel.agency17" target="_blank">Travel Insurance</a></li>
              <li><a href="/article/bohol">Article</a></li>
            </ul>
          </div>
          
          <div className={classes.column}>
            <h4>Why Choose JL TA?</h4>
            <ul>
              <li><a href="/why-choose-jlta">Our Certifications</a></li>
              <li><a href="/why-choose-jlta">Trusted Partner Status</a></li>
              <li><a href="/why-choose-jlta">Proof of Legitimacy</a></li>
            </ul>
          </div>
          
          <div className={classes.column}>
            <h4>Information</h4>
            <ul>
              <li><a href="#cookies-policy">Cookies Policy</a></li>
              <li><a href="/privacy-policy">Privacy Policy</a></li>
              <li><a href="/terms-and-conditions">Terms and Conditions</a></li>
              <li><a href="/terms-of-use">Terms of use</a></li>
            </ul>
          </div>
        </div>
        <div className={classes.bottomSection}>
          <div className={classes.social}>
            <a href="#facebook"><i className="fab fa-facebook-f"></i></a>
            <a href="#twitter"><i className="fab fa-twitter"></i></a>
          </div>
          <p>© 2024 JL Travel Agency All rights reserved</p>
          {/* <p>Department of Tourism Accreditation Certificate # 00000000</p> */}
        </div>
      </div>
    </footer>
  );
};

export default PublicFooter;

