import React from 'react';
import { FaPhone, FaWhatsapp, FaFacebook } from 'react-icons/fa';
import classes from './FloatingIcons.module.css';

const FloatingIcons = () => {
  return (
    <div className={classes.floatingIconsContainer}>
      <a href="tel:+639386091908" className={`${classes.icon} ${classes.phoneIcon} ${classes.blink}`} aria-label="Contact Us">
        <FaPhone size={30} />
      </a>
      <a href="https://wa.me/+639386091908" target="_blank" rel="noopener noreferrer" className={`${classes.icon} ${classes.whatsappIcon} ${classes.blink}`} aria-label="WhatsApp">
        <FaWhatsapp size={30} />
      </a>
      <a href="https://facebook.com/jl.travel.agency17" target="_blank" rel="noopener noreferrer" className={`${classes.icon} ${classes.facebookIcon} ${classes.blink}`} aria-label="Facebook">
        <FaFacebook size={30} />
      </a>
    </div>
  );
};

export default FloatingIcons;

