import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import MouseActivityTracker from './components/common/MouseActivityTracker/MouseActivityTracker';
import ClearOnBrowserClose from './components/LocalStorage/ClearOnBrowserClose';
import AppRoutes from './Routes';
import UserContext from './contexts/UserContext';

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [sharedState, setSharedState] = useState({}); 
  const [isModalOpen, setModalOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleModal = () => {
    setModalOpen(!isModalOpen);  
  };

  return (
    <div className="App">
      <Router>
        <UserContext.Provider value={{ sharedState, setSharedState, isModalOpen, toggleModal }}>
          <MouseActivityTracker />
          <AppRoutes isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
          <ClearOnBrowserClose />
        </UserContext.Provider>
      </Router>
    </div>
  );
}

export default App;